<template>
<b-card-group deck>
    <b-card header="Details" bg-variant="light">
        <b-table-lite borderless small stacked :fields="fields.left" :items="tableData">
            <template v-slot:cell(phone)="row">{{ row.item.phone | phoneNumber }}</template>
        </b-table-lite>
    </b-card>
    <b-card header="Mentor" bg-variant="light">
        <b-table-lite borderless small stacked :fields="fields.right" :items="tableData">
            <template v-slot:cell(mentor)="row">
                <b-link :to="{name:'profileView', params:{ id: row.item.mentor.id, activeTabIndex: 0 }}">{{ mentor.fullname }}</b-link>
            </template>
            <template v-slot:cell(status)="row">
                <b-badge v-if="isGraduated" variant="success">Graduated {{ row.item.graduated | date('M/d/yyyy') }}</b-badge>
                <b-badge v-else-if="isDropped" variant="danger">Dropped {{ row.item.dropped | date('M/d/yyyy') }}</b-badge>
                <b-badge v-else variant="primary">{{status}}</b-badge>
<!--                <b-badge v-if="isBehind" variant="warning">Behind</b-badge>-->
            </template>
        </b-table-lite>
    </b-card>
</b-card-group>
</template>


<script>
import { Component, Vue, Prop} from 'vue-property-decorator';
import {NULL_USER, User} from '@/model/user';
import formatters from '@/util/formatters';
import userDao from '@/dao/user_dao';
import trackDao from "@/dao/track_dao";
import _ from 'underscore';
import store from '@/store/store';
import {NULL_TRACK} from "@/model/track";
import {errorToastOptions} from "../../../../util/formatters";

@Component({
    filters: {
        phoneNumber: (p) => {
            return (p || {}).formatted || '';
        },
        date: formatters.date
    },
    asyncComputed: {
        isBehind: {
            async get() {
                if (_.isNull(this.user) || _.isUndefined(this.user) ||
                    _.isEqual(this.user, NULL_USER) || _.isNaN(this.user.id) ||
                    this.isGraduated || this.isDropped) {
                    return false;
                }
                try {
                    await store.dispatch('trainees/isTraineeBehind', this.user.id);
                }
                catch (error) {
                    this.$bvToast.toast(error.message, errorToastOptions);
                    return false;
                }
                return store.getters['trainees/isTraineeBehind'](this.user.id);
            }
        }
    }
})

export default class TraineeSnippet extends Vue {
    @Prop({type: User}) user;

    mentor = NULL_USER;
    track = NULL_TRACK;

    get fields() {
        return {
            left: [{
                key: 'name',
                label: 'Name:',
                sortable: false
            }, {
                key: 'ubcId',
                label: 'UBC ID:'
            }, {
                key: 'email',
                label: 'E-mail:',
                sortable: false
            }, {
                key: 'phone',
                label: 'Phone:',
                sortable: false
            }, {
                key: 'organization',
                label: 'Contractor:',
                sortable: false
            }],
            right: [{
                key: 'mentor',
                label: 'Mentor:',
                sortable: false
            }, {
                key: 'track',
                label: 'Track:',
                sortable: false
            }, {
                key: 'status',
                label: 'Status:',
                sortable: false
            }]
        };
    }

    get tableData() {
        const user = this.user;
        // console.log(user);
        return [{
            user,
            name: user.fullname,
            ubcId: user.ubcId,
            email: user.email,
            phone: user.phone,
            mentor: this.mentor,
            organization: user.organization.name,
            track: _.isEqual(this.track, NULL_TRACK) ? '' : this.track.title,
            graduated: user.graduated,
            dropped: user.dropped,
            _rowVariant: 'light'
        }];
    }

    get isGraduated() {
        //Consider also checking this.user.traineeStatus.status === 'Graduated'
        return !_.isNull(this.user.graduated) && !_.isUndefined(this.user.graduated);
    }

    get isDropped() {
        //Consider also checking this.user.traineeStatus.status === 'Dropped'
        return !_.isNull(this.user.dropped) && !_.isUndefined(this.user.dropped) && !this.isGraduated;
    }

    get status() {
        if (!!this.user.traineeStatus && !!this.user.traineeStatus.status) {
            return this.user.traineeStatus.status;
        }
        else {
            return 'Unknown';
        }
    }

    async mounted() {
        //Load trainee extras on mount for (a) full detail, and (b) reactivity
        if (!!this.user.id) {
            try {
                const userWithExtra = await userDao.getUserById(this.user.id);
                //Set status
                this.user.dropped = userWithExtra.dropped;
                this.user.graduated = userWithExtra.graduated;
                this.user.traineeStatus = userWithExtra.traineeStatus;
                //Set mentor
                this.user.mentorId = userWithExtra.mentorId;
                this.mentor = await userDao.getUserById(this.user.mentorId);
                //Set track
                this.user.trackId = userWithExtra.trackId;
                this.track = await trackDao.getTrackById(this.user.trackId);
            }
            catch (error) {
                this.$bvToast.toast(error.message, errorToastOptions);
            }
        }
        // console.log(this.user);
    }

}
</script>


<style scoped>
</style>
