<template>
    <b-card no-body class="clearfix">
        <b-card-header v-b-toggle.search-criteria>
            <strong>Search Criteria</strong>
            <div class="float-right">
                <font-awesome-icon :icon="expanded ? 'angle-double-up' : 'angle-double-down'"/>
            </div>
        </b-card-header>
        <b-collapse id="search-criteria" v-model="expanded">
            <b-card-body @keyup.enter="doSearch">
                <b-row>
                    <b-col cols="md-3">
                        <b-form-group label-for="search-security-level" label="Security Level:">
                            <b-input-group size="sm">
                                <b-select id="search-security-level" v-model="criteria.role" :options="roleOptions">
                                    <template v-slot:first>
                                        <option :value="null"> - </option>
                                    </template>
                                </b-select>
                                <b-input-group-addon>
                                    <b-button @click="criteria.role = null"><font-awesome-icon icon="times"/></b-button>
                                </b-input-group-addon>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col cols="md-3">
                        <b-form-group label-for="search-username" label="Username:">
                            <b-input-group size="sm">
                                <b-input id="search-username" placeholder="Username" v-model="criteria.username"/>
                                <b-input-group-addon>
                                    <b-button @click="criteria.username = null"><font-awesome-icon icon="times"/></b-button>
                                </b-input-group-addon>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col cols="md-3">
                        <b-form-group label-for="search-lastname" label="Last Name:">
                            <b-input-group size="sm">
                                <b-input id="search-lastname" placeholder="Last Name" v-model="criteria.lastname"/>
                                <b-input-group-addon>
                                    <b-button @click="criteria.lastname = null"><font-awesome-icon icon="times"/></b-button>
                                </b-input-group-addon>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col cols="md-3">
                        <b-form-group label-for="search-ubcid" label="UBC ID:">
                            <b-input-group size="sm">
                                <b-input id="search-ubcid" v-model="criteria.ubcId" placeholder="UBC ID" input-class="form-control form-control-sm" />
<!--                                <ubc-id id="search-ubcid" v-model="criteria.ubcId" input-class="form-control form-control-sm" />-->
                                <b-input-group-addon>
                                    <b-button @click="criteria.ubcId = null"><font-awesome-icon icon="times"/></b-button>
                                </b-input-group-addon>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="md-3">
                        <b-form-group label-for="search-district" label="District:">
                            <b-input-group size="sm">
                                <b-select id="search-district" v-model="criteria.district" :options="districtOptions">
                                    <template v-slot:first>
                                        <option :value="null"> - </option>
                                    </template>
                                </b-select>
                                <b-input-group-addon>
                                    <b-button @click="criteria.district = null"><font-awesome-icon icon="times"/></b-button>
                                </b-input-group-addon>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col cols="md-3">
                        <b-form-group label-for="search-council" label="Council:">
                            <b-input-group size="sm">
                                <b-select id="search-council" v-model="criteria.council" :options="councilOptions">
                                    <template v-slot:first>
                                        <option :value="null"> - </option>
                                    </template>
                                </b-select>
                                <b-input-group-addon>
                                    <b-button @click="criteria.council = null"><font-awesome-icon icon="times"/></b-button>
                                </b-input-group-addon>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col cols="md-3">
                        <b-form-group label-for="search-contractor-type" label="Contractor Type:">
                            <b-input-group size="sm">
                                <b-select id="search-contractor-type" v-model="criteria.contractorType" :options="contractorTypeOptions">
                                    <template v-slot:first>
                                        <option :value="null"> - </option>
                                    </template>
                                </b-select>
                                <b-input-group-addon>
                                    <b-button @click="criteria.contractorType = null"><font-awesome-icon icon="times"/></b-button>
                                </b-input-group-addon>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="md-3">
                        <b-form-group label-for="search-contractor" label="Contractor:">
                            <b-input-group size="sm">
                                <b-select id="search-contractor" v-model="criteria.contractor" :options="contractorOptions">
                                    <template v-slot:first>
                                        <option :value="null"> - </option>
                                    </template>
                                </b-select>
                                <b-input-group-addon>
                                    <b-button @click="criteria.contractor = null"><font-awesome-icon icon="times"/></b-button>
                                </b-input-group-addon>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col cols="md-3">
                        <b-form-group label-for="search-organization" label="Organization:">
                            <b-input-group size="sm">
                                <b-select id="search-organization" v-model="criteria.organization" :options="organizationOptions">
                                    <template v-slot:first>
                                        <option :value="null"> - </option>
                                    </template>
                                </b-select>
                                <b-input-group-addon>
                                    <b-button @click="criteria.organization = null"><font-awesome-icon icon="times"/></b-button>
                                </b-input-group-addon>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col cols="md-3">
                        <b-form-group label-for="search-state" label="State/Province:">
                            <b-input-group size="sm">
                                <b-select id="search-state" v-model="criteria.state" :options="stateOptions">
                                    <template v-slot:first>
                                        <option :value="null"> - </option>
                                    </template>
                                </b-select>
                                <b-input-group-addon>
                                    <b-button @click="criteria.state = null"><font-awesome-icon icon="times"/></b-button>
                                </b-input-group-addon>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="md-3">
                        <b-form-group label-for="search-graduation-date" label="Graduation Date:">
                            <picker-of-dates id="search-graduation-date"
                                             placeholder="Graduation Date"
                                             v-model="criteria.graduationDate"
                                             format="M/d/yyyy"
                                             clearable range/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="md-3">
                        <b-form-group label-for="search-program" label="Program:">
                            <b-input-group size="sm">
                                <b-select id="search-program"
                                          v-model="criteria.programs"
                                          :options="programs"
                                          :select-size="8"
                                          multiple/>
                                <b-input-group-addon>
                                    <b-button @click="criteria.programs = [];criteria.session = null"><font-awesome-icon icon="times"/></b-button>
                                </b-input-group-addon>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col cols="md-3" v-if="!!criteria.programs && criteria.programs.length > 0">
                        <b-form-group label-for="search-session" label="Session:">
                            <picker-of-dates v-model="criteria.session"
                                             type="month"
                                             placeholder="Session Month/Year"
                                             format="MMMM yyyy"
                                             clearable/>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="md-3">
                        <b-form-group label-for="search-trainee-status" label="Trainee Status:">
                            <b-input-group size="sm">
                                <b-select id="search-trainee-status" v-model="criteria.traineeStatus" :options="traineeStatusOptions">
                                    <template v-slot:first>
                                        <option :value="null"> - </option>
                                    </template>
                                </b-select>
                                <b-input-group-addon>
                                    <b-button @click="criteria.traineeStatus = null"><font-awesome-icon icon="times"/></b-button>
                                </b-input-group-addon>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col cols="md-3">
                        <b-form-group label-for="search-mentor-status" label="Mentor Status:">
                            <b-input-group size="sm">
                                <b-select id="search-mentor-status" v-model="criteria.mentorStatus" :options="mentorStatuses">
                                    <template v-slot:first>
                                        <option :value="null"> - </option>
                                    </template>
                                </b-select>
                                <b-input-group-addon>
                                    <b-button @click="criteria.mentorStatus = null"><font-awesome-icon icon="times"/></b-button>
                                </b-input-group-addon>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col cols="md-3">
                        <b-form-group label-for="search-user-status" label="User Status:">
                            <b-input-group size="sm">
                                <b-select id="search-user-status" v-model="criteria.userStatus" :options="userStatuses">
                                    <template v-slot:first>
                                        <option :value="null"> - </option>
                                    </template>
                                </b-select>
                                <b-input-group-addon>
                                    <b-button @click="criteria.userStatus = null"><font-awesome-icon icon="times"/></b-button>
                                </b-input-group-addon>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-collapse>
        <b-card-footer class="clearfix">
            <b-button-group size="sm" v-if="expanded">
                <b-button variant="primary" @click="doSearch">Search</b-button>
                <b-button class="ml-2" variant="success" @click="doExport" v-if="activeUser.isAnAdministrator()">Export</b-button>
                <b-button class="ml-2" variant="info" @click="clear">Clear Search Criteria</b-button>
            </b-button-group>
            <b-button class="float-right" size="sm" variant="dark" v-if="activeUser.isAnAdministrator()" @click="newUser">New User</b-button>
        </b-card-footer>
    </b-card>
</template>
<script>

import {Vue, Prop, Component} from 'vue-property-decorator';
import UbcId from '@/components/shared/UbcId.vue';
import PickerOfDates from '@/components/shared/PickerOfDates';
import _ from 'underscore';
import {sprintf} from 'sprintf-js';
import {RoleOptions} from '@/model/security_level';
import download from '@/util/downloader';
import {SecurityLevel} from "../../../model/security_level";
import {errorModalOptions, errorToastOptions} from "../../../util/formatters";

@Component({
    components: {
        UbcId,
        PickerOfDates
    }
})
export default class SearchCriteria extends Vue {

    expanded = true;

    get activeUser() {
        return this.$store.getters['userSession/getUser'];
    }

    clear() {
        this.criteria.clear();
    }

    get criteria() {
        return this.$store.getters['users/searchCriteria'];
    }

    get roleOptions() {
        const activeUser = this.activeUser;
        const options = _.chain(RoleOptions)
                .filter((o) => {
                    // For Administrators, allow the search for any user type
                    // For Instructors, allow searching for Mentors and Trainees
                    // For Mentors, only allow searching for Trainees
                    return activeUser.isAnAdministrator() || activeUser.securityLevel.ord < o.value.ord;
                })
                .value();
        return options;
    }

    get districtOptions() {
        return _.map(this.$store.getters['common/districts'], (d) => {
            return {
                value: d.id,
                text: d.name
            };
        });
    }

    get councilOptions() {

        const councils = _.groupBy(this.$store.getters['common/councils'], (c) => c.districtName);

        return _.map(councils, (cs, district) => {
            return {
                label: district,
                options: _.map(cs, (c) => {
                    return {
                        value: c.id,
                        text: sprintf('%s (%s)', c.name, c.displayId)
                    };
                })
            };
        });
    }

    get states() {
        return this.$store.getters['common/states'];
    }

    get stateOptions() {
        const grouped = _.groupBy(this.states, (s) => s.countryAbbreviation);

        return [{
            label: 'United States of America',
            options: this.getStateOptions(grouped.US)
        }, {
            label: 'Canada',
            options: this.getStateOptions(grouped.CA)
        }];
    }

    get programs() {
        return [
            'Trainee Program 1',
            'Trainee Program 2',
            'Trainee Program 3',
            'Trainee Program 4',
            'Mentor Program 1',
            'Mentor Program 3',
            'Instructors'
        ];
    }

    get traineeStatuses() {
        return this.$store.getters['trainees/statuses'];
    }

    get traineeStatusOptions() {
        return _.map(this.traineeStatuses, (s) => {
            return {
                value: s.id,
                text: s.status
            };
        });
    }

    get mentorStatuses() {
        return [{
            value: true,
            text: 'Active'
        }, {
            value: false,
            text: 'Inactive'
        }];
    }

    get userStatuses() {
        return [{
            value: true,
            text: 'Enabled'
        }, {
            value: false,
            text: 'Disabled'
        }];
    }

    get contractorTypes() {
        return this.$store.getters['organizations/getContractorTypes'];
    }

    get contractorTypeOptions() {
        return _.map(this.contractorTypes, (t) => {
            return {
                value: t.id,
                text: t.type
            };
        });
    }

    get organizations() {
        return this.$store.getters['organizations/getOrganizations'];
    }

    get contractorOptions() {
        return _.chain(this.organizations)
                .filter((o) => !!o.type)
                .sortBy((o) => o.name)
                .map((o) => {
                    return {
                        value: o.id,
                        text: o.name
                    }
                })
                .value();
    }

    get organizationOptions() {
        return _.chain(this.organizations)
            .filter((o) => !o.type)
            .sortBy((o) => o.name)
            .map((o) => {
                return {
                    value: o.id,
                    text: o.name
                }
            })
            .value();
    }

    getStateOptions(states) {
        return _.chain(states)
            .sortBy((s) => s.state)
            .map((s) => {
                return {
                    value: s.id,
                    text: sprintf('%s (%s)', s.state, s.stateAbbreviation)
                }
            })
            .value();
    }

    doSearch() {
        this.$emit('search');
    }

    async doExport() {
        const criteria = this.criteria.serialize();
        try {
            download('POST', '/api/v1/export/users', JSON.stringify(criteria));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    newUser() {
        if (this.activeUser.isAnAdministrator()) {
            this.$router.push('/admin/profile/new');
            // this.$router.push({path: '/admin/profile/new', params: {activeTabIndex: 0}});
        }
    }

    async mounted() {
        _.each([
            'common/loadStates',
            'common/loadDistricts',
            'common/loadCouncils',
            'trainees/loadTraineeStatuses',
            'organizations/loadContractorTypes',
            'organizations/loadOrganizations'
        ], (action) => {
            this.$store.dispatch(action)
                .catch(error => {
                    this.$bvToast.toast(error.message, errorToastOptions);
                });
        });
    }
}
</script>
<style scoped>

</style>