<template>
    <b-card-group deck>
        <b-card header="Details" bg-variant="light">
            <b-table-lite borderless small stacked :fields="fields.middle" :items="tableData">
                <template v-slot:cell(phone)="row">{{ row.item.phone.formatted }}</template>
            </b-table-lite>
        </b-card>
    </b-card-group>
</template>
<script>

import { Component, Vue, Prop } from 'vue-property-decorator';
import {Administrator, NULL_USER} from '@/model/user';

@Component
export default class AdministratorSnippet extends Vue {

    @Prop({type: Administrator, default: NULL_USER}) user;

    get fields() {
        return {
            middle: [{
                key: 'name',
                label: 'Name:',
                sortable: false
            }, {
                key: 'title',
                label: 'Title:',
                sortable: false
            }, {
                key: 'organization',
                label: 'Organization:',
                sortable: false
            }, {
                key: 'email',
                label: 'Email:',
                sortable: false
            }, {
                key: 'phone',
                label: 'Phone:',
                sortable: false
            }]
        };
    }

    get tableData() {
        const user = this.user;

        return [{
            name: user.fullname,
            title: user.title,
            email: user.email,
            phone: user.phone,
            organization: user.organization.name
        }];
    }
}
</script>
<style scoped>

</style>
