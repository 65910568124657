<template>
    <b-row>
        <b-col>
            <b-row>
                <b-col>
                    <user-search-form
                            @search="doSearch"/>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-card>
                        <b-row>
                            <b-col cols="4">
                                <b-pagination :total-rows="searchResults.length" :per-page="perPage" v-model="currentPage"/>
                            </b-col>
                            <b-col cols="8">
                                <b-form-group :label="'Records Per Page: ' + perPage">
                                    <b-input type="range" v-model="perPage" min="10" max="100"></b-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            Page {{ currentPage }} of {{ pages }}
                        </b-row>
                        <b-row>
                            <b-table small striped hover bordered no-local-sorting stacked="md" head-variant="dark"
                                     :items="searchResults"
                                     :fields="fields"
                                     :sort-by.sync="sortKey"
                                     :sort-desc.sync="sortDesc"
                                     :per-page="perPage"
                                     :current-page="currentPage">
                                <template v-slot:cell(username)="row">
                                    <b-link :to="{name: 'profileView', params: {id: row.item.id, activeTabIndex: 0}}">{{ row.item.username }}</b-link>
                                </template>
                                <template v-slot:cell(role)="row">
                                    {{ row.item.securityLevel.toString() }}
                                </template>
                                <template v-slot:cell(buttons)="row">
                                    <b-link @click="row.toggleDetails">{{ row.detailsShowing ? 'Hide' : 'Show' }} Details</b-link> |
                                    <b-link :to="{name: 'profileView', params: {id: row.item.id, activeTabIndex: 0}}">View Profile</b-link> |
                                    <b-link :to="{name: 'schedule', params: {userId: row.item.id}}">Show Schedule</b-link> |
                                    <b-link @click="resetPassword(row.item)">Reset Password</b-link>
                                    <template v-if="isRegistrable(row.item.id)">
                                        |
                                        <b-link @click="register(row.item)">Register</b-link>
                                    </template>
                                </template>
                                <template v-slot:row-details="row">
                                    <TraineeSnippet v-if="row.item.isATrainee()" :user="row.item"/>
                                    <MentorSnippet v-if="row.item.isAMentor()" :user="row.item"/>
                                    <InstructorSnippet v-if="row.item.isAnInstructor()" :user="row.item"/>
                                    <AdministratorSnippet v-if="row.item.isAnAdministrator()" :user="row.item"/>
                                </template>
                            </b-table>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <b-pagination :total-rows="searchResults.length" :per-page="perPage" v-model="currentPage"/>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
            <!--REGISTRATION MODAL-->
            <b-modal :id="registrationModalId"
                     size="xl"
                     scrollable
                     title="Registration"
                     header-bg-variant="dark"
                     header-text-variant="white">
                <UserRegistration
                        :user="registerUser"
                        @done="closeRegistration" />
            </b-modal>
        </b-col>
    </b-row>
</template>

<script>
import {Component, Vue} from 'vue-property-decorator';
import _ from 'underscore';
import userSessionDao from '@/dao/user_session_dao';
import {SecurityLevel, RoleOptions} from '@/model/security_level';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import store from '@/store/store';
import TraineeSnippet from '@/views/private/users/snippet/TraineeSnippet.vue';
import MentorSnippet from '@/views/private/users/snippet/MentorSnippet.vue';
import InstructorSnippet from '@/views/private/users/snippet/InstructorSnippet.vue';
import AdministratorSnippet from '@/views/private/users/snippet/AdministratorSnippet.vue';
import UserRegistration from '@/views/private/users/UserRegistration.vue';
import UserSearchForm from '@/views/private/users/UserSearchForm.vue';
import {sprintf} from 'sprintf-js';
import {NULL_USER, SearchCriteria} from "../../../model/user";
import {errorModalOptions, errorToastOptions} from "../../../util/formatters";

const fields = [{
    key: 'firstName',
    label: 'First Name',
    sortable: true
}, {
    key: 'lastName',
    label: 'Last Name',
    sortable: true
}, {
    key: 'username',
    label: 'Username',
    sortable: true
}, {
    key: 'role',
    label: 'Role',
    sortable: true
}, {
    key: 'buttons',
    label: '',
    sortable: false
}];

@Component({
    components: {
        MentorSnippet,
        TraineeSnippet,
        InstructorSnippet,
        AdministratorSnippet,
        UserRegistration,
        UserSearchForm
    }
})

export default class Users extends Vue {

    registerUser = NULL_USER;

    sortKey = null;
    sortDesc = false;
    currPage = 1;
    perPage = 10;

    isRegistrable(userId) {
        return this.$store.getters['registration/isRegistrableUserId'](userId);
    }

    get currentPage() {
        if (this.currPage > this.pages) {
            return this.pages;
        } else {
            return this.currPage;
        }
    }

    set currentPage(num) {
        const page = parseInt(num, 10);

        switch (true) {
            case _.isNaN(page):
            case 0 >= page:
                this.currPage = 1;
                break;

            case page > this.pages:
                this.currPage = this.pages;
                break;

            default:
                this.currPage = page;
        }
    }

    get pages() {
        return Math.ceil(this.searchResults.length / this.perPage) || 1;
    }

    get searchResults() {

        const user = this.currentUser;
        const users = this.$store.getters['users/users'];

        const sortedRows = _.chain(users)
            .filter((u) => {
                switch (true) {
                    case user.isAnAdministrator():
                        return true;

                    case user.isAnInstructor():
                        return u.isATrainee() || u.isAMentor();

                    case user.isAMentor():
                        return u.isATrainee();

                    default:
                        return false;
                }
            })
            .sortBy((u) => {
                switch (this.sortKey) {
                    case 'username': return (u.username || '').toLowerCase();
                    case 'firstName': return (u.firstName || '').toLowerCase();
                    case 'role': return u.securityLevel.toString();
                    case 'lastName':
                    default: return (u.lastName || '').toLowerCase();
                }
            })
            .value();

        return this.sortDesc ? sortedRows.reverse() : sortedRows;
    }

    get fields() {
        return fields;
    }

    get currentUser() {
        return this.$store.getters['userSession/getUser'];
    }

    get isMentorUser() {
        return !_.isUndefined(this.currentUser) && this.currentUser.isAMentor();
    }

    get roleOptions() {

        const user = this.currentUser;

        return _.filter(RoleOptions, (opt) => {

            if (user.isAnAdministrator()) {
                return true;
            }

            if (user.isAnInstructor()) {
                return !opt.value.equals(SecurityLevel.ADMINISTRATOR) && !opt.value.equals(SecurityLevel.INSTRUCTOR);
            }

            if (user.isAMentor()) {
                return opt.value.equals(SecurityLevel.TRAINEE);
            }

            return false;
        });
    }

    get showTrackFilter() {
        return SecurityLevel.TRAINEE.equals(this.roleFilter);
    }

    async doSearch() {
        const criteria = this.$store.getters['users/searchCriteria'];
        await this.search(criteria);
    }

    async doMentorAutoSearch() {
        //Separate from user store search criteria for "auto-search"
        const autoSearchCriteria = new SearchCriteria();
        autoSearchCriteria.role = SecurityLevel.TRAINEE;
        await this.search(autoSearchCriteria);
    }

    async search(criteria) {
        const searchCriteria = criteria.serialize();
        try {
            const {limited, size} = await this.$store.dispatch('users/search', searchCriteria);
            this.currentPage = 1;
            if (limited) {
                this.$bvToast.toast(sprintf('The search results have been limited to %d users. Please choose more specific search criteria.', size), {
                    variant: 'danger',
                    title: 'Results Truncated',
                    solid: true
                });
            }
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    clearSearch() {
        this.usernameFilter = '';
        this.firstNameFilter = '';
        this.lastNameFilter = '';
        this.roleFilter = SecurityLevel.INVALID;

        this.$store.commit('users/setUsers', []);
    }

    async beforeRouteEnter(to, from, next) {
        const user = store.getters['userSession/getUser'];
        if (!user || user.isATrainee()) {
            return next(false);
        }
        const label = user.isAnAdministrator() ? 'Users' : (
            user.isAnInstructor() ? 'Mentors and Trainees' : 'Trainees'
        );
        store.commit('setBreadcrumbs', [
            Breadcrumb.create('Administration', {name: 'adminMain'}, false),
            Breadcrumb.create(sprintf('Search %s', label), null, true)
        ]);
        next();
    }

    async mounted() {
        this.$store.getters['users/searchCriteria'].clear();
        if (this.isMentorUser) {
            await this.doMentorAutoSearch();
        }
        try {
            _.each([
                'registration/loadRegistrableUserIds',
                'registration/loadCancelableUserIds'
            ], (action) => {
                this.$store.dispatch(action)
                    .catch(error => {
                        this.$bvToast.toast(error.message, errorToastOptions);
                    });
            });
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }

    get registrationModalId() {
        if (_.isNull(this.registerUser) || _.isEqual(this.registerUser, NULL_USER)) {
            return 'user-registration-modal-invalid';
        }
        else {
            return sprintf('user-registration-modal-%d', this.registerUser.id);
        }
    }

    register(user) {
        this.registerUser = user;
        //For some reason, assigning registerUser and getting the ID to show the modal don't seem to be synchronous
        setTimeout(() => {
            this.$bvModal.show(this.registrationModalId);
        }, 500);
    }

    closeRegistration() {
        this.$bvModal.hide(this.registrationModalId);
    }

    async resetPassword(user) {

        const username = user.username;

        this.$bvModal.msgBoxConfirm(sprintf("Are you sure you want to reset %s %s's password?", user.firstName, user.lastName), {
            title: 'Reset Password Confirmation',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: 'Yes',
            cancelVariant: 'secondary',
            cancelTitle: 'No',
            centered: true
        }).then(async (yesClicked) => {
            if (yesClicked) {
                try {
                    await userSessionDao.resetPassword(username);
                }
                catch (error) {
                    await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
                }
            }
        });
    }
}
</script>
<style scoped>

</style>
