<template>
    <b-card-group deck>
        <b-card header="Details" bg-variant="light">
            <b-table-lite borderless small stacked :fields="fields" :items="tableData">
                <template v-slot:cell(phone)="row">{{ row.item.phone.formatted }}</template>
                <template v-slot:cell(email)="row"><a :href="'mailto:'+row.item.email">{{row.item.email}}</a></template>
            </b-table-lite>
        </b-card>
        <b-card header="Trainees" bg-variant="light">
            <ol>
                <li v-for="trainee in trainees">
                    <b-link :to="{name: 'profileView', params: {id: trainee.id, activeTabIndex: 0}}">{{trainee.fullname}}</b-link> |
                    <b-link :to="{name: 'schedule', params: {userId: trainee.id}}">schedule</b-link>
                </li>
            </ol>
        </b-card>
    </b-card-group>
</template>
<script>

import { Component, Vue, Prop } from 'vue-property-decorator';
import {Mentor, NULL_USER} from '@/model/user';
import { phoneNumber } from '@/util/formatters';
import userDao from '@/dao/user_dao';
import {errorToastOptions} from "../../../../util/formatters";

@Component({
    filters: {
        phoneNumber
    }
})
export default class MentorSnippet extends Vue {

    @Prop({type: Mentor, default: NULL_USER}) user;

    trainees = [];

    get fields() {
        return [{
            key: 'name',
            label: 'Name:'
        }, {
            key: 'ubcId',
            label: 'UBC ID:'
        }, {
            key: 'title',
            label: 'Title:'
        }, {
            key: 'organization',
            label: 'Contractor:'
        }, {
            key: 'email',
            label: 'Email:'
        }, {
            key: 'phone',
            label: 'Phone:'
        }];
    }

    get tableData() {
        const user = this.user;

        return [{
            user,
            name: user.fullname,
            ubcId: user.ubcId,
            title: user.title,
            email: user.email,
            phone: user.phone,
            organization: user.organization.name,
            _rowVariant: 'light'
        }];
    }

    async mounted() {
        const traineeIds = this.user.traineeIds;
        try {
            this.trainees = await userDao.getUsersByIds(traineeIds);
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
            this.trainees = [];
        }
    }
}
</script>
<style scoped>

</style>
